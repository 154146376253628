<template>
  <div class="page-container stock-balances-page">
    <portal to="page-name">Consulta Saldo</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Consulta Saldo</h1>
        <button class="btn btn-gray btn-icon btn-icon-left float-right"
                @click="openBrasindice">
          <fa-icon :icon="['fal', 'capsules']"/>Pesquisar no brasíndice
        </button>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-6 form-group">
            <label for="product-search" class="form-label">Produto</label>
            <div class="input-group">
              <input type="text" id="product-search" class="form-input"
                     v-model="filter.search" maxlength="100"
                     autocomplete="nope" @keypress.enter="load"
                     placeholder="Informe o nome do produto ou código de barras">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-3">
            <label class="form-label">Local</label>
            <select id="stock-place" class="form-select"
                    v-model="filter.placeId">
              <option value="">Todos os locais</option>
              <option v-for="place in places" :value="place.id" :key="place.id">
                {{ place.name }}
              </option>
            </select>
          </div>
          <div class="column">
            <label class="form-label">Com saldo</label>
            <select id="stock-place" class="form-select"
                    v-model="filter.hasBalance">
              <option value="">Todos</option>
              <option value="false">Não</option>
              <option value="true">Sim</option>
            </select>
          </div>
          <div class="column col-auto mb-2" style="align-self: flex-end">
            <button class="btn btn-primary btn-icon btn-icon-left" @click="load">
              <fa-icon :icon="['fal', 'search']"/>Pesquisar
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Movimentação</p>
              <p class="empty-subtitle">
                Nenhum produto encontrado. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th>Produto</th>
                <th>Unidade</th>
                <th>Local</th>
                <th>Lote/validade</th>
                <th class="text-right">Quantidade</th>
                <th class="text-right" width="100px">Custo médio</th>
                <th width="50px"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in data.items" :key="i">
                <td>
                  <span class="hover-link" @click="openBalances(item)">
                    {{ item.product.name }}
                  </span>
                </td>
                <td>{{ item.product.outputUnit }}</td>
                <td>{{ item.place ? item.place.name : 'Sem movimentação' }}</td>
                <td>
                  <span v-if="item.lot && item.lot.name">
                    {{ item.lot.name }} <small>({{ item.lot.validity | date }})</small>
                  </span>
                  <span v-else>Sem lote</span>
                </td>
                <td class="text-right text-bold text-info">{{ item.quantity | number(3) }}</td>
                <td class="text-right">{{ item.cost | currency }}</td>
                <td class="text-right">
                  <button class="btn btn-neutral btn-action btn-icon btn-sm"
                          :disabled="!item.place"
                          @click="openBalances(item)">
                    <fa-icon :icon="['fal', 'info-circle']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </template>
      </div>
      <div class="card-footer" v-if="data.items.length > 0">
        <span class="mr-2">Total de produtos:
          <span class="ml-2 text-primary text-bold">{{ data.total }}</span>
        </span>|
        <span class="ml-1 mr-2">Quantidade total:
          <span class="ml-2 text-primary text-bold">{{ quantityTotal | number(3) }}</span>
        </span>|
        <span class="ml-1">Total em estoque:
          <span class="ml-2 text-primary text-bold">{{ valueTotal | currency }}</span>
        </span>
      </div>
    </div>
    <dx-modal title="Movimentação"
              id="modal-stock-management" size="lg"
              v-if="balance.show"
              v-model="balance.show">
      <div class="loading loading-lg" v-if="balance.loading" />
      <template v-else>
        <div class="card-product mb-2">
          <div class="columns">
            <div class="column col-12 form-group">
              Local: <strong>{{ balance.item.place.name }}</strong>
            </div>
            <div class="column col-6 col-md-12 col-sm-12">
              Produto: <strong>{{ balance.item.name }}</strong>
            </div>
            <div class="column col-6 col-md-12 col-sm-12">
              <div v-if="balance.item.lot && balance.item.lot.id">
                Lote: <strong>{{ balance.item.lot.name }}</strong>
                <small> (validade: {{ balance.item.lot.validity | date }})</small>
              </div>
            </div>
          </div>
        </div>
        <div class="empty mt-2" v-if="balance.items && balance.items.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Movimentação</p>
          <p class="empty-subtitle">
            Este produto ainda não foi movimentado no sistema.
          </p>
        </div>
        <table class="table" v-else>
          <thead>
          <tr>
            <th width="140px">Data</th>
            <th>Tipo</th>
            <th>Descrição</th>
            <th class="text-center" width="100px">Conversão</th>
            <th class="text-right" width="100px">Quantidade</th>
            <th class="text-right" width="100px">Saldo</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in balance.items" :key="i">
            <td>{{ item.date | date('DD/MM/YYYY HH:mm') }}</td>
            <td>{{ getType(item.type) }}</td>
            <td>{{ item.for.name }}</td>
            <td class="text-center">
              x{{ item.product.conversion || 1 }}
            </td>
            <td class="text-right">
              <span class="text-error" v-if="item.type === 'output'">
                {{ item.quantity * -1 | number(3) }}
              </span>
              <span class="text-primary" v-else>
                {{ item.quantity | number(3) }}
              </span>
            </td>
            <td class="text-right">{{ item.balance | number(3) }}</td>
          </tr>
          </tbody>
        </table>
      </template>
      <template slot="footer">
        <button class="btn"
                @click="balance.show = false">Sair</button>
      </template>
    </dx-modal>
    <dx-modal title="Consulta Brasíndice"
              id="modal-brasindice" size="lg"
              v-if="brasindice.show"
              v-model="brasindice.show">
      <div class="card-filter">
        <div class="columns">
          <div class="column col-2 form-group">
            <label for="brasindice-version" class="form-label">Versão</label>
            <select class="form-select"
                    id="brasindice-version"
                    v-model="brasindice.version">
              <option v-for="(item, i) in brasindice.versions"
                      :value="item.version" :key="i">{{ item.version }}</option>
            </select>
          </div>
          <div class="column col-6 form-group">
            <label for="brasindice-name" class="form-label">Nome</label>
            <input type="text" class="form-input" @keypress.enter="loadBrasindice"
                   id="brasindice-name" placeholder="Nome do medicamento"
                   v-model="brasindice.search">
          </div>
          <div class="column col-auto mb-2"
               style="display: flex; align-items: flex-end">
            <button class="btn btn-gray btn-icon btn-icon-left input-group-btn"
                    :class="{loading: brasindice.loading}"
                    @click="loadBrasindice" :disabled="brasindice.loading">
              <fa-icon :icon="['fal', 'search']"/> Pesquisar
            </button>
          </div>
        </div>
      </div>
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th width="100px">Código</th>
          <th>Nome</th>
          <th>Fabricante</th>
          <th>Fator</th>
          <th class="text-center" width="100px">Quantidade</th>
          <th class="text-right" width="100px">Valor unitário</th>
          <th class="text-right" width="100px">Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in brasindice.items" :key="i">
          <td>
            {{ item.code }}<br>
            {{ item.tussCode | tuss }}
          </td>
          <td>
            {{ item.name }}<br>
            <small>{{ item.complement }}</small>
          </td>
          <td>{{ item.manufacturer ? item.manufacturer.name : '' }}</td>
          <td>{{ item.factor }}</td>
          <td class="text-center">{{ item.quantity }}</td>
          <td class="text-right">{{ item.value | currency }}</td>
          <td class="text-right">{{ item.total | currency }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn"
                @click="brasindice.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      path: '/stock-balances',
      debounceTimeout: null,
      loading: false,
      balance: {
        show: false,
        loading: false,
        items: [],
      },
      brasindice: {
        show: false,
        loading: false,
        version: '',
        search: '',
        versions: [],
        items: [],
      },
      places: [],
      filter: {
        search: '',
        hasBalance: true,
        placeId: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 20,
        offset: 0,
      },
    };
  },
  mounted() {
    this.loadPlaces();
    this.load();
  },
  computed: {
    quantityTotal() {
      return this.data.items
        .reduce((a, b) => a + b.quantity, 0);
    },
    valueTotal() {
      return this.data.items
        .reduce((a, b) => a + (b.cost * b.quantity), 0);
    },
  },
  methods: {
    load() {
      this.loading = true;

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      if (this.filter.placeId) {
        params.placeId = this.filter.placeId;
      }

      if (this.filter.hasBalance) {
        params.hasBalance = this.filter.hasBalance;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadPlaces() {
      this.places = [];

      const params = {
        active: true,
        limit: 0,
      };

      return this.$http
        .get('/places', { params })
        .then(({ data }) => {
          this.places = data.items;
        })
        .catch(() => {});
    },
    loadBalances(item) {
      this.balance.items = [];

      const params = {
        placeId: item.place.id,
      };

      if (item.lot && item.lot.id) {
        params.lotId = item.lot.id;
      }

      return this.$http
        .get(`/products/${item.id}/balances`, { params })
        .then(({ data }) => {
          this.balance.items = data;
        })
        .catch(() => {});
    },
    loadBrasindice() {
      this.brasindice.loading = true;

      const params = {
        version: this.brasindice.version,
        limit: 50,
      };

      if (this.brasindice.search) {
        params.search = this.brasindice.search.trim();
      }

      return this.$http
        .get('/brasindice', { params })
        .then(({ data }) => {
          this.brasindice.items = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.brasindice.loading = false;
        });
    },
    loadBrasindiceVersions() {
      return this.$http
        .get('/brasindice/versions')
        .then(({ data }) => {
          this.brasindice.versions = data;
        })
        .catch(() => {})
        .finally(() => {
          if (this.brasindice.versions.length > 0) {
            this.brasindice.version = this.brasindice.versions[0].version;
          }
        });
    },
    async openBalances(item) {
      this.balance.loading = true;
      this.balance.show = true;
      this.balance.item = {
        id: item.product.id,
        name: item.product.name,
        place: item.place,
        lot: item.lot,
      };
      await this.loadBalances(item);
      this.balance.loading = false;
    },
    async openBrasindice() {
      this.brasindice.items = [];
      this.brasindice.search = '';
      this.brasindice.show = true;
      if (this.brasindice.versions.length === 0) {
        await this.loadBrasindiceVersions();
      }
      this.loadBrasindice();
    },
    getType(type) {
      if (type === 'entry' || type === 'transfer') {
        return 'Entrada';
      }
      if (type === 'output') {
        return 'Saída';
      }
      return 'Inventário';
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.stock-balances-page {
}
#modal-brasindice {
  .modal-body {
    min-height: 3rem;
  }
  .table {
    td {
      font-size: $font-size-xs;
    }
  }
}
#modal-stock-management {
  .modal-body {
    min-height: 3rem;
  }
  .card-product {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $layout-spacing-lg;
  }
}
</style>
